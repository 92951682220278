import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import ContactForm from "../components/ContactForm"
import PostSubmit from "../components/PostSubmit"
import Map from "../components/Map"
import Ready from "../components/Ready"
import Cta from "../components/Cta"

export const ContactPageTemplate = ({
  title,
  subtitle,
  formTitle,
  map,
  breadcrumbs,
}) => {
  const [sent, setSent] = useState({
    status: false,
    title: "Thanks for reaching out",
    message: "We'll get back to you as soon as we can!",
  })
  return (
    <>
      <Header title={title} subtitle={subtitle}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 31">
          <path d="M189.153 26.93c121.47 16.124 113.612-29.358 199.673-15.782 86.224 13.636 288.928-22.41 257.672-4.071-31.54 18.36-102.164 101.113-188.704 93.718-86.378-7.336-95.318-12.98-165.35-7.846-70.315 5.154-201.805 20.804-266.342-3.834-64.537-24.637-73.321-64.17-7.26-78.236 66.02-14.206 37.237-.054 170.311 16.05z" />
        </svg>
      </Header>
      <Breadcrumbs data={breadcrumbs} />
      <Map
        data={map}
        form={
          sent.status ? (
            <PostSubmit title={sent.title}>{sent.message}</PostSubmit>
          ) : (
            <ContactForm
              setSent={setSent}
              sent={sent}
              title={formTitle}
              formType="General"
            />
          )
        }
      />
      <Ready svg>
        <Cta href="https://app.skipthedepot.com/register">Get started</Cta>
      </Ready>
    </>
  )
}

const ContactPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, seoDesc, formTitle, map, additional },
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  return (
    <Layout
      title={`${title} | SkipTheDepot | Book A Pickup Today`}
      seoDesc={seoDesc}
    >
      <ContactPageTemplate
        title={title}
        subtitle={subtitle}
        formTitle={formTitle}
        map={map}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default ContactPage

export const PageQuery = graphql`
  query contactQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        formTitle
        map {
          title
          subtitle
          data {
            name
            address
            email
            phone
            url
            image {
              relativePath
            }
          }
        }
      }
    }
  }
`
